import "./reset.css";
import "normalize.css";
import "@cleeng/mediastore-sdk/dist/styles/msdFont.css";
import "./App.css";
import React, { useState } from "react";
import { Config } from "@cleeng/mediastore-sdk";
import NavitgationBar from "Components/NavigationBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckOut from "Pages/CheckOut";
import CheckOutWithExtraProps from "Pages/CheckOutWithExtraProps";
import CheckOutWithoutTerms from "Pages/CheckOutWithoutTerms";
import OnePageMS from "Pages/OnePageMS";
import MyAccount from "Pages/MyAccount";
import Register from "Pages/Register";
import Login from "Pages/Login";
import Capture from "Pages/Capture";
import CheckoutConsents from "Pages/CheckoutConsents";
import CheckOutWithCaptcha from "Pages/CheckOutWithCaptcha";
import Purchase from "Pages/Purchase";
import PurchaseRedirect from "Pages/PurchaseRedirect";
import RedeemGift from "Pages/RedeemGift";
import PasswordReset from "Pages/PasswordReset";
import Subscriptions from "Pages/Subscriptions";
import SubscriptionSwitches from "Pages/SubscriptionSwitches";
import PlanDetails from "Pages/PlanDetails";
import PaymentInfo from "Pages/PaymentInfo";
import TransactionList from "Pages/TransactionList";
import UpdateProfile from "Pages/UpdateProfile";
import Modal from "Components/Modal/Modal";
import "@adyen/adyen-web/dist/adyen.css";
import "react-loading-skeleton/dist/skeleton.css";
import ReCaptchaComponent from "Pages/ReCaptcha";
import { ThemeProvider } from "styled-components";

const App = () => {
  const [toggleModalVisibility, setToggleModalVisibility] = useState(false);
  // const [backgroundColor, setBackgroundColor] = React.useState("red");
  // const changeBackgroundColor = () => {
  //   setBackgroundColor("green");
  // };
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []); <- this code will rerender the page via empty change state update
  function refreshPage() {
    window.location.reload(false);
  }

  // this string is patched during deployment
  console.log("private - 5.6.3-455-0d65b0540787ac89764f37d851d709b3c4d2c0aa");

  const [env, setEnv] = useState(() => {
    let env = "staging";
    const CLEENG_ENVIRONMENT = localStorage.getItem("CLEENG_ENVIRONMENT");

    if (CLEENG_ENVIRONMENT === null) {
      return env;
    } else if (CLEENG_ENVIRONMENT === "staging" || "sandbox") {
      return CLEENG_ENVIRONMENT;
    } else if (env === "staging" || "sandbox") {
      return env;
    } else {
      return "staging";
    }
  });

  // localStorage.setItem("i18nextLng", "fr");
  Config.setTermsUrl("https://www.nfl.com/legal/subscriptions_terms");
  const { protocol, hostname, port } = window.location;
  const url = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
  Config.setCheckoutPayPalUrls({
    successUrl: `${url}/checkout`,
    cancelUrl: `${url}/checkout`,
    errorUrl: `${url}/checkout`,
  });
  // Config.setHiddenPaymentMethods([539843844]);

  const [publisher, setPublisher] = useState(() => {
    let publisher = "157310007";
    const PUBLISHER_ID = localStorage.getItem("CLEENG_PUBLISHER_ID");

    if (PUBLISHER_ID === null) {
      return publisher;
    } else if (PUBLISHER_ID && PUBLISHER_ID.length === 9) {
      return PUBLISHER_ID;
    } else if (publisher && publisher.length === 9) {
      return publisher;
    } else {
      return "157310007";
    }
  });
  const [offer, setOffer] = useState(() => {
    let offer = "S991191280_PL";
    const CLEENG_OFFER_ID = localStorage.getItem("CLEENG_OFFER_ID");

    if (CLEENG_OFFER_ID === null) {
      return offer;
    } else if (
      (CLEENG_OFFER_ID && CLEENG_OFFER_ID.length === 13) ||
      (CLEENG_OFFER_ID && CLEENG_OFFER_ID.length === 12)
    ) {
      return CLEENG_OFFER_ID;
    } else if (
      (offer && offer.length === 13) ||
      (offer && offer.length === 12)
    ) {
      return offer;
    } else {
      return "S991191280_PL";
    }
  });

  window.addEventListener("MSSDK:Purchase-loaded", (evt) => {
    console.log("MSSDK:Purchase-loaded");
  });
  window.addEventListener("MSSDK:Purchase-loaded", (evt) => {
    console.log("MSSDK:Purchase-loaded", evt.detail.order.offerId);
  });
  window.addEventListener("MSSDK:Purchase-loaded", (evt) => {
    console.log(
      "MSSDK:Purchase-loaded",
      evt.detail.order.offerId,
      evt.detail.order.customerId
    );
  });

  window.addEventListener("MSSDK:purchase-successful", (evt) => {
    console.log("MSSDK:purchase-successful");
  });
  window.addEventListener("MSSDK:purchase-successful", (evt) => {
    console.log("MSSDK:purchase-successful", evt.detail.payment);
  });
  window.addEventListener("MSSDK:purchase-successful", (evt) => {
    console.log(
      "MSSDK:purchase-successful",
      evt.detail.payment.offerId,
      evt.detail.payment.customerId
    );
  });

  window.addEventListener("MSSDK:purchase-failed", (evt) => {
    console.log("MSSDK:purchase-failed", evt.detail);
  });

  window.addEventListener("MSSDK:Adyen-error", (evt) => {
    console.log("MSSDK:Adyen-error", evt.detail);
  });

  window.addEventListener("MSSDK:redeem-coupon-success", (evt) => {
    console.log("MSSDK:redeem-coupon-success");
  });
  window.addEventListener("MSSDK:redeem-coupon-success", (evt) => {
    console.log("MSSDK:redeem-coupon-success", evt.detail.detail);
  });
  window.addEventListener("MSSDK:redeem-coupon-success", (evt) => {
    console.log(
      "MSSDK:redeem-coupon-success",
      evt.detail.detail.coupon,
      evt.detail.detail.source,
      evt.detail.order.customerId
    );
  });

  window.addEventListener("MSSDK:redeem-coupon-failed", (evt) => {
    console.log("MSSDK:redeem-coupon-failed");
  });
  window.addEventListener("MSSDK:redeem-coupon-failed", (evt) => {
    console.log("MSSDK:redeem-coupon-failed", evt.detail.detail);
  });
  window.addEventListener("MSSDK:redeem-coupon-failed", (evt) => {
    console.log(
      "MSSDK:redeem-coupon-failed",
      evt.detail.detail.coupon,
      evt.detail.detail.source
    );
  });

  window.addEventListener("MSSDK:redeem-coupon-button-clicked", (evt) =>
    console.log("MSSDK:redeem-coupon-button-clicked", evt.detail)
  );

  window.addEventListener("MSSDK:redeem-button-clicked", (evt) =>
    console.log("MSSDK:redeem-button-clicked", evt.detail)
  );

  window.addEventListener("MSSDK:unsubscribe-button-clicked", (evt) =>
    console.log("MSSDK:unsubscribe-button-clicked", evt.detail)
  );

  window.addEventListener("MSSDK:unsubscribe-action-confirmed", (evt) =>
    console.log("MSSDK:unsubscribe-action-confirmed")
  );
  window.addEventListener("MSSDK:unsubscribe-action-confirmed", (evt) =>
    console.log("MSSDK:unsubscribe-action-confirmed", evt.detail.detail)
  );
  window.addEventListener("MSSDK:unsubscribe-action-confirmed", (evt) =>
    console.log(
      "MSSDK:unsubscribe-action-confirmed",
      evt.detail.detail.cancellationReason
    )
  );

  window.addEventListener("MSSDK:unsubscribe-action-cancelled", (evt) =>
    console.log("MSSDK:unsubscribe-action-cancelled", evt.detail)
  );

  window.addEventListener("MSSDK:resume-button-clicked", (evt) =>
    console.log("MSSDK:resume-button-clicked", evt.detail)
  );

  window.addEventListener("MSSDK:resume-action-confirmed", (evt) =>
    console.log("MSSDK:resume-action-confirmed", evt.detail)
  );

  window.addEventListener("MSSDK:resume-action-cancelled", (evt) =>
    console.log("MSSDK:resume-action-cancelled", evt.detail)
  );

  window.addEventListener("MSSDK:switch-button-clicked", (evt) =>
    console.log("MSSDK:switch-button-clicked", evt.detail)
  );

  window.addEventListener("MSSDK:switch-popup-action-cancelled", (evt) =>
    console.log("MSSDK:switch-popup-action-cancelled", evt.detail)
  );

  window.addEventListener("MSSDK:switch-popup-action-successful", (evt) => {
    console.log("MSSDK:switch-popup-action-successful", evt.detail);
  });

  window.addEventListener("MSSDK:switch-popup-action-failed", (evt) =>
    console.log("MSSDK:switch-popup-action-failed", evt.detail)
  );

  window.addEventListener("MSSDK:cancel-switch-button-clicked", (evt) =>
    console.log("MSSDK:cancel-switch-button-clicked", evt.detail)
  );

  window.addEventListener("MSSDK:cancel-switch-action-cancelled", (evt) =>
    console.log("MSSDK:cancel-switch-action-cancelled", evt.detail)
  );

  window.addEventListener("MSSDK:cancel-switch-action-triggered", (evt) =>
    console.log("MSSDK:cancel-switch-action-triggered", evt.detail)
  );

  window.addEventListener("MSSDK:cancel-switch-action-successful", (evt) =>
    console.log("MSSDK:cancel-switch-action-successful", evt.detail)
  );

  window.addEventListener("MSSDK:cancel-switch-action-failed", (evt) =>
    console.log("MSSDK:cancel-switch-action-failed", evt.detail)
  );

  window.addEventListener("MSSDK:edit-payment-button-clicked", (evt) =>
    console.log("MSSDK:edit-payment-button-clicked")
  );
  window.addEventListener("MSSDK:edit-payment-button-clicked", (evt) =>
    console.log("MSSDK:edit-payment-button-clicked", evt.detail)
  );

  window.addEventListener("MSSDK:update-payment-details-successful", (evt) =>
    console.log("MSSDK:update-payment-details-successful", evt.detail)
  );

  window.addEventListener("MSSDK:update-payment-details-failed", (evt) =>
    console.log("MSSDK:update-payment-details-failed", evt.detail)
  );

  window.addEventListener(
    "MSSDK:remove-payment-details-button-clicked",
    (evt) =>
      console.log("MSSDK:remove-payment-details-button-clicked", evt.detail)
  );

  window.addEventListener(
    "MSSDK:remove-payment-details-action-confirmed",
    (evt) =>
      console.log("MSSDK:remove-payment-details-action-confirmed", evt.detail)
  );

  window.addEventListener(
    "MSSDK:remove-payment-details-action-cancelled",
    (evt) =>
      console.log("MSSDK:remove-payment-details-action-cancelled", evt.detail)
  );

  window.addEventListener("MSSDK:auth-failed", (evt) =>
    console.log("MSSDK:auth-failed")
  );

  window.addEventListener("MSSDK:good-news-page", (evt) => {
    console.log("MSSDK:good-news-page", evt.detail);
  });

  window.addEventListener("MSSDK:payment-page", (evt) => {
    console.log("MSSDK:payment-page", evt.detail);
  });

  const changeConfig = (env, publisher, offer) => {
    setEnv(env);
    setPublisher(publisher);
    setOffer(offer);
  };

  Config.setEnvironment(env);
  Config.setPublisher(publisher.toString());
  Config.setOffer(offer);
  Config.setMyAccountUrl("https://mssdk-demo-app.stardustlab.com/myaccount");
  // Config.setTheme({
  //   fontColor: "#3ed9d9",
  //   backgroundColor: "#757c94",
  //   cardColor: "#1c0722",
  //   successColor: "#84d3f5",
  //   primaryColor: "#5260cb",
  //   loaderColor: "#8589c7",
  //   errorColor: "#ff0000",
  //   secondaryColor: "#37ea06",
  //   logoUrl:
  //     "https://img.freepik.com/free-vector/colorful-letter-gradient-logo-design_474888-2309.jpg",
  // });

  React.useEffect(() => {
    console.log(window.location.search);
  }, [window.location.search]);

  return (
    <ThemeProvider
      theme={
        {
          //   backgroundColor: "#0c0722",
          //   cardColor: "#5c0722",
          //   errorColor: "#ff0000",
          //   fontColor: "#94ffb0",
          //   loaderColor: "#5589c7",
          //   logoUrl:
          //     "https://img.freepik.com/free-vector/colorful-letter-gradient-logo-design_474888-2309.jpg",
          //   primaryColor: "#ebb22f",
          //   secondaryColor: "#ab5497",
          //   successColor: "#141edb",
        }
      }
    >
      {/* <button onClick={changeBackgroundColor}>change color</button> */}

      <div className="App">
        <Router>
          <NavitgationBar setToggleModalVisibility={setToggleModalVisibility} />
          <Routes>
            <Route path="/myaccount" element={<MyAccount />} />
            <Route path="/checkout" element={<CheckOut offer={offer} />} />
            <Route
              path="/allLowerComponents"
              element={<OnePageMS offerId={offer} refreshPage={refreshPage} />}
            />
            <Route path="/Register" element={<Register />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Capture" element={<Capture />} />
            <Route path="/CheckoutConsents" element={<CheckoutConsents />} />
            <Route
              path="/CheckOutWithCaptcha"
              element={<CheckOutWithCaptcha />}
            />
            <Route path="/Purchase" element={<Purchase offer={offer} />} />
            <Route
              path="/CheckOutWithExtraProps"
              element={<CheckOutWithExtraProps offer={offer} />}
            />
            <Route
              path="/CheckOutWithoutTerms"
              element={<CheckOutWithoutTerms offer={offer} />}
            />
            <Route
              path="/PurchaseRedirect"
              element={<PurchaseRedirect offer={offer} />}
            />
            <Route path="/RedeemGift" element={<RedeemGift />} />
            <Route path="/PasswordReset" element={<PasswordReset />} />
            <Route path="/Subscriptions" element={<Subscriptions />} />
            <Route
              path="/SubscriptionSwitches"
              element={<SubscriptionSwitches offer={offer} />}
            />
            <Route path="/PlanDetails" element={<PlanDetails />} />
            <Route path="/PaymentInfo" element={<PaymentInfo />} />
            <Route path="/TransactionList" element={<TransactionList />} />
            <Route path="/UpdateProfile" element={<UpdateProfile />} />
            <Route
              path="/ReCaptchaComponent"
              element={<ReCaptchaComponent />}
            />
          </Routes>
          {toggleModalVisibility ? (
            <Modal
              changeConfig={changeConfig}
              env={env}
              publisher={publisher}
              offer={offer}
              onClose={() => setToggleModalVisibility(false)}
              setToggleModalVisibility={setToggleModalVisibility}
              refreshPage={refreshPage}
            />
          ) : null}
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
